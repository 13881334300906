/* eslint-disable object-shorthand */

export const PACKAGE_BUILD_DATE = '2024-10-07T07:05:12.210Z';
export const PACKAGE_NAME = 'invision-customercare';
export const PACKAGE_VERSION = '24.4.10000-1577257';

export default {
    PACKAGE_BUILD_DATE: PACKAGE_BUILD_DATE,
    PACKAGE_NAME: PACKAGE_NAME,
    PACKAGE_VERSION: PACKAGE_VERSION
};
